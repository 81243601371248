<template>
  <v-progress-linear
    v-if="isLoading"
    color="primary"
    indeterminate
  ></v-progress-linear>

  <v-container
    v-else
    class="my-12"
  >
    <v-alert
      text
      color="info"
      class="mb-12"
    >
      <h3 class="text-h5">
        Sections sensibles - DEMO
      </h3>

      <div>
        Vous êtes actuellement sur l'interface de démonstration pour les sections sensibles.
        Cet endroit sert à présenter le formulaire lors de la journée porte ouverte.
        Il n'est pas possible d'envoyer de demande via ce formulaire.
        <br>
        L'interface permettant l'envoi de votre demande de préinscription se trouve
        <router-link
          to="/pss"
          class="info--text"
        >ici</router-link>
        .
      </div>

      <v-divider
        class="my-4 info"
        style="opacity: 0.22"
      ></v-divider>

      <v-row
        align="center"
        no-gutters
      >
        <v-col class="grow">
          La démo est actuellement {{ currentDemoId ? 'ouverte' : 'fermée' }}.
        </v-col>

        <v-spacer></v-spacer>

        <v-col class="shrink">
          <v-dialog
            v-model="isVisible"
            max-width="400"
            :persistent="isSaving"
            v-if="isSuperUser"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Configurer
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="info white--text">Configurer la démo</v-card-title>

              <v-card-text class="pt-5">
                <v-form>
                  <v-select
                    label="Instance"
                    v-model="instanceId"
                    :items="instances"
                    item-text="id"
                    item-value="id"
                    filled
                    height="80"
                  >
                    <template v-slot:selection="{ attrs, item }">
                      <div>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.start_date | formatDate }}
                            au {{ item.end_date | formatDate }}
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            {{ item.start_hour }} à {{ item.end_hour }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </div>
                    </template>

                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.start_date | formatDate }}
                          au {{ item.end_date | formatDate }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          {{ item.start_hour }} à {{ item.end_hour }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  @click="isVisible = false"
                  text
                  :disabled="isSaving"
                >
                  Annuler
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  @click="saveDemo"
                  text
                  color="info"
                  :loading="isSaving"
                  :disabled="!instanceId"
                >
                  {{ actionTitle }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-alert>

    <para-form
      v-if="currentDemoId"
      :cursus="cursus"
      isDemo
    ></para-form>
  </v-container>
</template>

<script>
import ParaForm from '@/components/guest/ParaForm.vue';

export default {
  name: 'ParaDemo',

  components: {
    ParaForm,
  },

  data: () => ({
    isVisible: false,
    isSaving: false,
    instanceId: null,
    currentDemoId: null,
    isLoading: true,
    cursus: [],
    instances: [],
  }),

  computed: {
    actionTitle() {
      if (this.currentDemoId === this.instanceId && this.instanceId) return 'Fermer';
      return 'Ouvrir';
    },

    isSuperUser() {
      return this.$store.getters.isAuth
        && (this.$store.getters?.hasParaRole || this.$store.getters?.hasAdminRole);
    },
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      if (this.isSuperUser) await this.$store.dispatch('getAccessToken');
      try {
        this.isLoading = true;
        const [dataDemoResponse, instancesResponse] = await Promise.all([
          this.getDataDemo(),
          this.getInstances(),
        ]);
        this.cursus = dataDemoResponse.data.cursus;
        this.instanceId = dataDemoResponse.data.instance_id;
        this.currentDemoId = this.instanceId;
        if (instancesResponse) this.instances = instancesResponse.data;
      } catch {
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: 'Une erreur est survenue, rechargez la page',
          color: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },

    getDataDemo() {
      return this.$http.get('guest/getDataDemo.php');
    },

    getInstances() {
      return this.isSuperUser
        ? this.$http.get('admin/instance-demo-get.php')
        : Promise.resolve();
    },

    async saveDemo() {
      await this.$store.dispatch('getAccessToken');
      try {
        this.isSaving = true;
        const response = await this.$http.post('admin/demo-save.php', { id: this.instanceId });
        if (this.instanceId === this.currentDemoId) this.instanceId = null;
        this.currentDemoId = this.instanceId;
        this.cursus = response.data;
        this.isVisible = false;
      } catch (error) {
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: 'Une erreur est survenue, réessayez',
          color: 'error',
        });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
