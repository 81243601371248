var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}}):_c('v-container',{staticClass:"my-12"},[_c('v-alert',{staticClass:"mb-12",attrs:{"text":"","color":"info"}},[_c('h3',{staticClass:"text-h5"},[_vm._v(" Sections sensibles - DEMO ")]),_c('div',[_vm._v(" Vous êtes actuellement sur l'interface de démonstration pour les sections sensibles. Cet endroit sert à présenter le formulaire lors de la journée porte ouverte. Il n'est pas possible d'envoyer de demande via ce formulaire. "),_c('br'),_vm._v(" L'interface permettant l'envoi de votre demande de préinscription se trouve "),_c('router-link',{staticClass:"info--text",attrs:{"to":"/pss"}},[_vm._v("ici")]),_vm._v(" . ")],1),_c('v-divider',{staticClass:"my-4 info",staticStyle:{"opacity":"0.22"}}),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"grow"},[_vm._v(" La démo est actuellement "+_vm._s(_vm.currentDemoId ? 'ouverte' : 'fermée')+". ")]),_c('v-spacer'),_c('v-col',{staticClass:"shrink"},[(_vm.isSuperUser)?_c('v-dialog',{attrs:{"max-width":"400","persistent":_vm.isSaving},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Configurer ")])]}}],null,false,2125885858),model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"info white--text"},[_vm._v("Configurer la démo")]),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',[_c('v-select',{attrs:{"label":"Instance","items":_vm.instances,"item-text":"id","item-value":"id","filled":"","height":"80"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_c('div',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start_date))+" au "+_vm._s(_vm._f("formatDate")(item.end_date))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.start_hour)+" à "+_vm._s(item.end_hour)+" ")])],1)],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.start_date))+" au "+_vm._s(_vm._f("formatDate")(item.end_date))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.start_hour)+" à "+_vm._s(item.end_hour)+" ")])],1)]}}],null,false,3418934913),model:{value:(_vm.instanceId),callback:function ($$v) {_vm.instanceId=$$v},expression:"instanceId"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","disabled":_vm.isSaving},on:{"click":function($event){_vm.isVisible = false}}},[_vm._v(" Annuler ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"info","loading":_vm.isSaving,"disabled":!_vm.instanceId},on:{"click":_vm.saveDemo}},[_vm._v(" "+_vm._s(_vm.actionTitle)+" ")])],1)],1)],1):_vm._e()],1)],1)],1),(_vm.currentDemoId)?_c('para-form',{attrs:{"cursus":_vm.cursus,"isDemo":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }